<!--
 * @Description: 用户审核--关联组织审核-待审核列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors  : 蒙川
 * @LastEditTime : 2020-01-13 14:29:52
 -->
<template>
  <div class="relevanceOrgAuditList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #searchSlot>
        <v-datepicker
          label="申请时间段"
          type="rangedatetimer"
          defaultTimeType="week"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        />
        <v-input
          label="姓名"
          v-model="searchParams.userName"
          :maxlength="10"
        ></v-input>
        <v-input
          label="手机号"
          v-model="searchParams.mobileNum"
          :maxlength="11"
        ></v-input>
        <v-select
          label="性别"
          v-model="searchParams.sex"
          :options="sexOps"
        ></v-select>
        <v-select2
          label="所属组织"
          v-model="searchParams.orgId"
          v-bind="orgParams"
        ></v-select2>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
        ></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <el-dropdown trigger="click" @command="auditHandle">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, status: 1 }">
              <span>审核通过</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, status: 2 }">
              <span>拒绝</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量通过"
          @click="batchHandle(scope.selectedData, 1)"
        ></v-button>
        <v-button
          text="批量拒绝"
          @click="batchHandle(scope.selectedData, 2)"
        ></v-button>
      </template>
      <el-dialog
        title="拒绝原因"
        width="650px"
        :visible.sync="dialogTextareaVisible"
      >
        <div>
          <v-textarea
            v-model="approveComments"
            placeholder="请输入拒绝原因"
            :width="610"
          ></v-textarea>
        </div>
        <div class="dialog-footer">
          <v-button
            class="cancel-button"
            type=""
            text="取 消"
            @click="dialogTextareaVisible = false"
          ></v-button>
          <v-button @click="confirmClick" text="确 定"></v-button>
        </div>
      </el-dialog>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL
} from './api'
import { sexMap, sexOps } from './map'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'element-ui'
import Vue from 'vue'
import moment from 'moment'
import { communityParams, tenantParams } from 'common/select2Params'

Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

export default {
  name: 'RelevanceOrgAuditList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      batchURL: batchURL,
      sexOps: sexOps,
      dialogTextareaVisible: false,
      approveComments: '',
      communityParams,
      tenantParams,
      postData: {},
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName'
        }
      },
      searchParams: {
        approveStatus: 0,
        userName: '',
        mobileNum: '',
        orgId: '',
        startTime: '',
        endTime: '',
        communityId: '',
        regionId: '',
        sex: undefined
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'sexText',
          label: '性别',
          formatter (row) {
            return sexMap[row.sex]
          }
        },
        {
          prop: 'communityAddress',
          label: '项目住址'
        },
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'companyName',
          label: '所属公司'
        },
        {
          prop: 'intime',
          label: '申请时间'
        }
      ]
    }
  },

  /// 计算属性处理，
  computed: {},

  methods: {
    // status 1通过 2拒绝
    batchHandle (selected, status) {
      let ids = selected.ids
      if (ids.length) {
        let postData = {
          userList: ids,
          approveStatus: status,
          approveComments: ''
        }
        if (status === 2) {
          // 拒绝必须添加操作备注
          this.addApproveComments(postData)
        } else {
          let _this = this
          this.$confirm('确定执行该操作？', {
            title: '提示'
          }).then(confirm => {
            // 通过直接进行请求
            if (confirm) {
              _this.handleRequest(postData)
            }
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
      }
    },

    // 操作的请求
    handleRequest (postData) {
      let _this = this
      this.$axios.post(this.batchURL, postData).then(res => {
        // 要清空postData
        _this.postData = {}
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    // 右侧更多按钮审核操作
    auditHandle (command) {
      let row = command.row
      let status = command.status
      let dataObject = `${row.userName}/${row.mobileNum}`
      let postData = {
        userList: [row.id],
        approveStatus: status,
        approveComments: '',
        dataObject: dataObject
      }
      if (status === 2) {
        // 拒绝必须添加操作备注
        this.addApproveComments(postData)
      } else {
        let _this = this
        this.$confirm('确认审核？', {
          title: '提示'
        }).then(confirm => {
          // 通过直接进行请求
          if (confirm) {
            _this.handleRequest(postData)
          }
        })
      }
    },

    // 填写备注
    addApproveComments (postData) {
      // 弹出填写备注的框
      this.postData = postData
      this.dialogTextareaVisible = true
    },

    // 点击确认按钮
    confirmClick () {
      if (this.approveComments.length) {
        this.dialogTextareaVisible = false
        let postData = Object.assign(this.postData, {
          approveComments: this.approveComments
        })
        this.handleRequest(postData)
      } else {
        this.$message({
          type: 'error',
          message: '拒绝原因不能为空!',
          center: true
        })
      }
    },

    edit (row) {
      this.$router.push({
        name: 'relevanceOrgAuditForm',
        query: {
          id: row.id
        }
      })
    }
  },
  mounted () {
    const { fromPage } = this.$route.query
    if (fromPage === 'workbench') {
      this.searchParams = {
        approveStatus: 0,
        userName: '',
        mobileNum: '',
        orgId: '',
        startTime: '',
        endTime: '',
        communityId: '',
        regionId: '',
        sex: undefined
      }
    } else {
      this.searchParams.startTime = moment().subtract(7,'d').format('YYYY-MM-DD HH:mm')
      this.searchParams.endTime = moment().format('YYYY-MM-DD HH:mm')
    }
  },
}
</script>

<style scope lang="scss">
.relevanceOrgAuditList-wrapper {
  .dialog-footer {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: flex-end;

    .cancel-button {
      margin-right: 20px;
    }
  }
}
</style>
